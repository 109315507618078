import {BrokenLink, Node, cloneNodes, deleteNode} from "../../entities/Node";
import {NodeResult, getNodeResults} from "../api/sphere/sphere";
import {NodeType, nodeTypeToString} from "../../entities/NodeType";

import ICore from "./ICore";
import {TempLink} from "../../entities/Link";
import IStorage from "../storage/IStorage";

class Core implements ICore {
    // ICore

    calculateSphere(
        nodes: Node[],
        onResult: (nodes: NodeResult[], brokenLinks: BrokenLink[]) => void
    ) {
        this._calculateSphere(nodes, onResult);
    }

    isNewNodeCorrect(title: string, nodeType: NodeType, links: TempLink[]) {
        return this._isNewNodeCorrect(title, nodeType, links);
    }

    needQuality(nodeType: NodeType) {
        return this._needQuality(nodeType);
    }

    qualities: number[];

    getAvailableNodeTypes(currentLinks: TempLink[]) {
        return this._getAvailableNodeTypes(currentLinks);
    }

    getAvailableLinks(
        currentNodeType: NodeType | undefined,
        currentLinks: TempLink[],
        editingNodeId: string | undefined
    ) {
        return this._getAvailableLinks(
            currentNodeType,
            currentLinks,
            editingNodeId
        );
    }

    getVisibleNodes() {
        return this._getVisibleNodes()
    }


    // Setup

    storage: IStorage;

    constructor(storage: IStorage) {
        this.storage = storage;
        this.qualities = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
    }

    // Internal

    private _calculateSphere = (
        nodes: Node[],
        onResult: (nodes: NodeResult[], brokenLink: BrokenLink[]) => void
    ) => {
        getNodeResults(nodes, (sphereResponse) => {
            onResult(sphereResponse.nodes, sphereResponse.broken_links);
        });
    };

    _getAvailableNodeTypes = (currentLinks: TempLink[]) => {
        let task = {
            label: nodeTypeToString(NodeType.Task),
            type: NodeType.Task,
        };

        let group = {
            label: nodeTypeToString(NodeType.Group),
            type: NodeType.Group,
        };

        let domain = {
            label: nodeTypeToString(NodeType.Domain),
            type: NodeType.Domain,
        };

        let key = {
            label: nodeTypeToString(NodeType.Key),
            type: NodeType.Key,
        };
        let result = [];

        result.push(key);
        result.push(domain);
        result.push(group);
        result.push(task);

        // ====================================================
        // добавление новых узлов
        // ====================================================

        // ключ может быть только один
        const keyExists = this.storage.nodes.find(
            (node) => node.type === NodeType.Key
        );
        if (keyExists) {
            result = result.filter((item) => {
                return item.type !== NodeType.Key;
            });
        }

        // ====================================================
        // добавление нового узла с линком к ключу
        // ====================================================

        // к ключу можно линковать только домены
        const keyInLinks = currentLinks.find((link) => {
            let node = this.storage.getNode(link.targetNodeId);
            return node?.type === NodeType.Key;
        });
        if (keyInLinks) {
            result = result.filter((item) => {
                return item.type === NodeType.Domain;
            });
        }

        // ====================================================
        // добавление нового узла с линком к домену
        // ====================================================

        // к домену можно линковать только направления и другие домены
        const domainInLinks = currentLinks.find((link) => {
            let node = this.storage.getNode(link.targetNodeId);
            return node?.type === NodeType.Domain;
        });
        if (domainInLinks) {
            result = result.filter((item) => {
                return (
                    item.type === NodeType.Group ||
                    item.type === NodeType.Domain
                );
            });
        }

        // ====================================================
        // добавление нового узла с линком к направлению
        // ====================================================

        // к направлению можно линковать только другие направления и задачи
        const groupInLinks = currentLinks.find((link) => {
            let node = this.storage.getNode(link.targetNodeId);
            return node?.type === NodeType.Group;
        });
        if (groupInLinks) {
            result = result.filter((item) => {
                return (
                    item.type === NodeType.Group || item.type === NodeType.Task
                );
            });
        }

        return result;
    };

    _getAvailableLinks = (
        currentNodeType: NodeType | undefined,
        currentLinks: TempLink[],
        editingNodeId: string | undefined
    ) => {
        var result = cloneNodes(this.storage.nodes);

        // убираем уже добавленные в список узлы
        for (let newLink of currentLinks) {
            result = result.filter((node) => node.id !== newLink.targetNodeId);
        }

        // убираем ссылку на самого себя (актуально при редактировании)
        result = result.filter((node) => node.id !== editingNodeId);

        // ключ ни к чему не линкуется
        if (currentNodeType === NodeType.Key) {
            return [];
        }

        // домен можно линковать только к другому домену или ключу
        if (currentNodeType === NodeType.Domain) {
            result = result.filter(
                (node) =>
                    node.type === NodeType.Domain || node.type === NodeType.Key
            );
        }

        // у домена может быть только одна связь
        if (currentNodeType === NodeType.Domain) {
            if (currentLinks.length > 0) {
                return [];
            }
        }

        // направление можно линковать только к доменам-листьям и другим направлениям
        if (currentNodeType === NodeType.Group) {
            result = result.filter(
                (node) =>
                    this._isDomainLeaf(node) || node.type === NodeType.Group
            );
        }

        // задачи можно линковать только к направлениям-листьям
        if (currentNodeType === NodeType.Task) {
            result = result.filter(
                (node) =>
                    node.type === NodeType.Group && node.is_leaf
            );
        }

        // циклы запрещены
        // TODO: реализовать проверку циклов

        return result.sort((a, b) => a.title.localeCompare(b.title));
    };

    _isNewNodeCorrect = (
        title: string,
        nodeType: NodeType,
        links: TempLink[]
    ) => {
        if (title.length === 0) {
            return false;
        }
        return true;
    };

    _needQuality = (nodeType: NodeType) => {
        return nodeType === NodeType.Domain;
    };

    _isDomainLeaf = (node: Node) => {
        if (node.type != NodeType.Domain) {
            return false;
        }
        for (const otherNode of this.storage.nodes) {
            // если на узел влияет другой домен, то не лист
            if (otherNode.type == NodeType.Domain) {
                for (const link of otherNode.links) {
                    if (link.targetNodeId == node.id) {
                        return false;
                    }
                }
            }
        }
        return true;
    };

    _getVisibleNodes = () => {
        let nodes = cloneNodes(this.storage.nodes)
        let invisibleNodeIds: string[] = []
        for (let node of nodes) {
            if (node.invisible) {
                invisibleNodeIds.push(node.id)
            }
        }
        for (const id of invisibleNodeIds) {
            nodes = deleteNode(id, nodes)
        }

        return nodes
    }

}
export default Core;
