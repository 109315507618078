
import { Link, TempLink } from "./Link";
import { NodeType } from "./NodeType";

export const maxQuality : number = 10

export type Node = {
    id: string;
    title: string;
    type: NodeType;
    quality?: number;
    complex_quality_factor?: number;
    level: number;
    draw_level: number;
    is_leaf: boolean;
    is_cycled: boolean;
    impact: number;
    links: Link[];
    hidden?: boolean; // помечает узел как скрываемый при экспорте проекции
    invisible?: boolean; // помечает узел как скрытый при чтении проекции
};

export type BrokenLink = {
    node_id: string;
    target_node_id: string;
};

export function cloneNode(node: Node): Node {
    let result = {...node}
    result.links = [...node.links]
    return result
}

export function cloneNodes(nodes: Node[]): Node[] {
    let result: Node[] = []
    for(const node of nodes) {
        result.push(cloneNode(node))
    }
    return result
}

export function deleteNode(nodeId: string, nodes: Node[]): Node[] {
    // обновляем ссылки (удаляем ссылки на удаляемый узел во всех прочих узлах)
    let result = nodes; // копию не делаем
    for (let index in result) {
        result[index].links = result[index].links.filter(
            (link) => link.targetNodeId !== nodeId
        );
    }
    // удаляем узел
    result = result.filter((node) => node.id !== nodeId);
    return result
}

export type NewNode = {
    title: string;
    type: NodeType;
    quality?: number;
    links: TempLink[];
}

export function nodeImpactString(impact: number): string {
    if (impact > 0) {
        if (impact > 1.0) {
            return `${Math.round(impact)}`
        }
        return `${Math.round(impact * 100) / 100.0}`
    }
    return "?"
}

export function getScaleFactor(weight: number, quality: number | undefined): number | null {
    if (weight === 0) {
        return null;
    }
    if (!quality) {
        return null;
    }
    if (quality == 10) {
        return null;
    }
    let scaleFactor = 1.0 / (quality / 10);
    return Math.round(scaleFactor * 100) / 100;
}
