import {useEffect, useRef, useState} from "react";
import "./Renderer.css";
import * as d3 from "d3";
import {drawScene} from "./DrawCore";
import {BrokenLink, Node, cloneNodes, deleteNode} from "../../../entities/Node";
import EmptySphere from "../emptySphere/EmptySphere";
import {SphereMode} from "../../../entities/Sphere";

function Renderer({nodes, brokenLinks, onEditNode, onAddNode, onMoveNodeAfter, onMoveNodeBefore}: IRendererProps) {
    const container = useRef<HTMLDivElement>(null);

    const [screenWidth, setScreenWidth] = useState(0);
    const [screenHeight, setScreenHeight] = useState(0);

    useEffect(() => {
        // подписываемся на изменения размера контейнера
        const resizeObserver = new ResizeObserver((event) => {
            const containerWidth = event[0].contentBoxSize[0].inlineSize;
            const containerHeight = event[0].contentBoxSize[0].blockSize;
            if (containerWidth > 0 && containerHeight > 0) {
                setScreenWidth(containerWidth);
                setScreenHeight(containerHeight);
            }
        });
        if (container.current) {
            resizeObserver.observe(container.current);
        }

        // перестраиваем SVG согласно размерам контейнера (размеры пока не учитываются)
        const svg = d3.select("#svg");
        if (screenWidth > 0 && screenHeight > 0) {
            drawScene(
                svg,
                screenWidth,
                screenHeight,
                nodes,
                brokenLinks,
                SphereMode.Source,
                onEditNode,
                onAddNode,
                onMoveNodeAfter,
                onMoveNodeBefore,
            );
        }

        return () => {
            // очищаем SVG
            svg.selectChildren().remove();
        };
    }, [screenHeight, screenWidth, nodes]);

    const getHeight = () => {
        return screenHeight > 0 ? screenHeight : 600;
    };

    return (
        <div className="Renderer-container">
            <div className="Renderer" ref={container}>
                {nodes.length > 0 && (
                    <svg
                        id="svg"
                        className="Renderer-svg"
                        style={{height: `${getHeight()}px`}}
                    />
                )}
                {nodes.length == 0 && (
                    <div className="Renderer-empty">
                        <EmptySphere />
                    </div>
                )}
            </div>
        </div>
    );
}

interface IRendererProps {
    nodes: Node[];
    brokenLinks: BrokenLink[];
    onEditNode: (nodeId: string) => void;
    onAddNode: (nodeId: string) => void;
    onMoveNodeAfter: (capturedNodeId: string, afterNodeId: string) => void;
    onMoveNodeBefore: (capturedNodeId: string, beforeNodeId: string) => void;
}

export default Renderer;
